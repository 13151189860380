.products-component-container {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column !important;
  justify-content: center;
  padding-bottom: 40px;
}
.products-component-container .compatibility-image img {
  height: 450px;
}
.products-component-container .compatibility-message {
  text-align: center;
  color: white;
}
.products-component-container .compatibility-message h1 {
  font-family: "Poppins-ExtraBold";
  font-size: 40px;
}
.products-component-container .compatibility-message h2 {
  font-family: "Poppins-Bold";
  font-size: 25px;
}
.products-component-container .compatibility-message p {
  font-size: 18px;
}
@media (min-width: 900px) {
  .products-component-container .compatibility-message {
    text-align: right;
  }
  .products-component-container .compatibility-message h1 {
    font-size: 50px;
  }
  .products-component-container .compatibility-message h2 {
    font-size: 30px;
  }
  .products-component-container .compatibility-message p {
    font-size: 20px;
  }
}
.products-component-container .title {
  text-align: center;
  color: white;
  margin: 20px 0 0;
}
.products-component-container .title h1 {
  font-size: 22px !important;
  font-family: "Poppins-ExtraBold";
}
.products-component-container .title h1 span {
  color: #2ad5de;
}
.products-component-container .title h2 {
  font-size: 30px;
  font-family: "Poppins-Bold";
}
@media (min-width: 900px) {
  .products-component-container .title {
    margin: 25px 0 0;
  }
  .products-component-container .title h1 {
    font-size: 25px !important;
  }
  .products-component-container .title h2 {
    font-size: 35px;
  }
}
.products-component-container span.MuiCircularProgress-root {
  color: #ffffff !important;
}
.products-component-container span.Circular-progress-payment {
  position: absolute;
  top: 50%;
  right: 25%;
  left: auto;
  color: #ff28dd !important;
}
.products-component-container span.Circular-progress-product {
  width: 50px;
  height: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -25px 0 0 -25px;
}
.products-component-container span.Circular-progress {
  position: absolute;
  top: 50%;
  right: 25%;
  left: auto;
  color: #ff28dd !important;
}
.products-component-container span.Circular-progress-client {
  position: absolute;
  top: 50%;
  right: 25%;
  left: auto;
  color: rgb(255, 255, 255) !important;
}

