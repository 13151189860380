/*** Variables ***/
.container-section-carousel-product .img-title {
  width: 35px;
  height: 35px;
}
@media (min-width: 900px) {
  .container-section-carousel-product .img-title {
    width: 50px;
    height: 50px;
  }
}
.container-section-carousel-product .swiper {
  width: 100%;
  padding-top: 25px;
  padding-bottom: 50px;
}
.container-section-carousel-product .swiper-slide {
  background-position: center;
  background-size: cover;
  width: 300px;
  height: "auto";
}
@media (min-width: 900px) {
  .container-section-carousel-product .swiper-slide {
    width: 350px;
  }
}
.container-section-carousel-product .swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  margin-left: 10px !important;
  margin-right: 10px !important;
  background-color: #b5a8bd;
}
.container-section-carousel-product .swiper-pagination-bullet-active {
  background-color: #ffffff;
}
.container-section-carousel-product .terms-and-conditions p {
  color: rgb(175, 175, 175);
}
.container-section-carousel-product .terms-and-conditions a {
  text-decoration: none;
  color: #ff00dd;
}

