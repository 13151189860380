.container-success-page {
  font-family: Poppins-Regular;
}
.container-success-page .img-success-payment {
  width: 170px;
  height: 170px;
}
@media (min-width: 900px) {
  .container-success-page .img-success-payment {
    width: 180px;
    height: 180px;
  }
}
.container-success-page .container-invoice-your-recharge .img-invoice {
  width: 100px;
  height: 100px;
  position: absolute;
  top: 0px;
  right: 20px;
}
@media (min-width: 900px) {
  .container-success-page .container-invoice-your-recharge .img-invoice {
    width: 127px;
    height: 127px;
    top: -15px;
  }
}

