.history-card {
  font-family: "Poppins-Regular";
}
.history-card .active-product {
  background: #9d50bb;
  background: -webkit-linear-gradient(to right, #6e48aa, #9d50bb);
  background: linear-gradient(to right, #6e48aa, #9d50bb);
}
.history-card .expired-product {
  color: #9c90b9;
  background-color: #ececf5;
  box-shadow: none;
  border: 1px solid #dedede;
}

