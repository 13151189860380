.container-graphic-card {
    h1 {
        font-family: "Poppins-Bold";
    }

    .chart-container {
        font-family: "Poppins-Regular";

        .chart {
            h4 {
                font-family: "Poppins-Medium";
            }
        }

        .data {
            .colored {
                font-family: "Poppins-Medium";
            }
        }
    }
}