/*** Variables ***/
@use "../variables" as v;
/*** Mixins ***/
@use "../mixins" as m;
/*** Tipografía ***/
@use "../typography.scss" as t;

.gift-not-found-container {
    .gift-not-found-legend {
        text-align: center;
    }
}

.gift-not-found-container,
.gift-activation-container {
    min-height: 100vh;
    background-color: #210946;
    color: white;
    width: 100%;
    .gift-header {
        padding: 0;
        display: flex;
        align-items: center;
        margin-bottom: 50px;
        .gift-header-image { 
            height: 500px;
            margin-top: 24px;
        }
        .logo {
            height: 60px;
            margin-bottom: 90px;
        }
        p {
            margin-top: 20px;
            font-family: t.$poppins-extrabold;
            font-size: 65px;
            line-height: 20px;
            margin: 0;
        }
        h1 {
            font-family: t.$poppins-extrabold;
            color: white;
            font-size: 75px;
            margin: 20px 0;
            font-style: italic;
        }
    }
    .gift-introduction-wrapper {
        margin: 0 auto;
    }
    .gift-introduction {
        border-radius: 20px 20px 0 0;
        background: rgb(29, 3, 68);
        background: linear-gradient(
            360deg,
            rgba(29, 3, 68, 1) 0%,
            rgba(67, 43, 116, 1) 100%
        );
        padding: 40px 50px 65px 15px;
        .gift-introduction-text {
           padding: 0 30px;
        }
        h1 {
            font-size: 40px;
            font-family: t.$poppins-bold;
        }
        p {
            font-size: 20px;
            span {
                font-family: t.$poppins-extrabold;
            }
        }
        .label {
            font-size: 24px;
            font-family: t.$poppins-extrabold;
            font-style: italic;
        }
        .emisor-image {
            height: 60px;
        }
        .message-box {
            border-radius: 20px;
            padding: 5px 30px;
            color: #373636;
            background-color: white;
            font-size: 16px;
            h3{
                font-size: 17px;
                font-family: t.$poppins-bold;
            }
        }
    }
    .bg-container {
        background-image: url("assets/images/landings/esim-activation/qr-bg-lines-1.png");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        display: flex;
        justify-content: center;
        .gift-code-container {
            margin: 85px 0 210px;
            display: flex;
            justify-content: center;
            .gift-code {
                display: flex;
                flex-direction: column;
                align-items: center;

                h1 {
                    font-size: 50px;
                    font-family: "Poppins-ExtraBold";
                    margin-bottom: 0;
                    margin-top: 20px;
                    text-align: center;
                }
                p {
                    font-size: 18px;
                    line-height: 28px;
                    text-align: center;
                }
                .gift-qr-text {
                    padding: 30px;
                }
                .gift-box {
                    margin-top: 30px;
                    padding: 30px 40px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background: rgba(0, 0, 0, 0.2);
                    font-size: 20px;
                    border-radius: 20px;
                    max-width: 800px;
                    width: 100%;
                    text-align: center;
                    h3 {
                        margin: 0;
                        font-family: "Poppins-SemiBold";
                    }
                    .gift-image {
                        display: flex;
                        justify-content: flex-end;
                        padding: 30px;
                        background: rgb(54, 7, 71);
                        background: linear-gradient(
                            346deg,
                            rgba(54, 7, 71, 1) 0%,
                            rgba(38, 15, 71, 1) 50%
                        );
                        border-radius: 30px;
                    }
                }
                h5 {
                    font-family: t.$poppins-regular;
                    font-size: 15px;
                    font-weight: 500;
                    span {
                        font-family: t.$poppins-extrabold;
                        font-style: italic;
                    }
                }
                .inactive {
                    h1 {
                        font-size: 35px;
                    }
                    p {
                        font-size: 17px;
                    }
                    h3 {
                        font-family: t.$poppins-bold;
                        line-height: 40px;
                    }
                    h4 {
                        font-family: t.$poppins-semibold;
                    }
                    h2 {
                        font-size: 40px;
                        font-family: t.$poppins-extrabold;
                        letter-spacing: 2px;
                    }
                }
            }
        }
        .gift-second-video {
            display: flex;
            align-items: center;
            h1 {
                font-size: 40px;
                font-family: t.$poppins-bold;
                line-height: 60px;
            }
            p {
                font-size: 18px;
                line-height: 30px;
            }
            .label {
                font-size: 24px;
                font-family: t.$poppins-extrabold;
            }
        }
    }
    .gift-introduction,
    .gift-second-video {
        .video-responsive {
            height: 0;
            overflow: hidden;
            padding-bottom: 56.25%;
            padding-top: 30px;
            position: relative;
        }
        .video-responsive iframe,
        .video-responsive object,
        .video-responsive embed {
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
        }
    }
    .gift-footer {
        background: #15002a;
        padding: 10px 0;
        text-align: center;
        margin-top: 150px;
    }
}

@media screen and (max-width: 899px) {
    .gift-activation-container {
        padding: 20px;
        .bg-container{ 
            .gift-second-video { 
                h1 { 
                    line-height: 48px;
                }
            }
        }
        .gift-header {
            margin-bottom: 15px;
            p {
                line-height: 72px;
                text-align: center;
            }
            h1 {
                margin: 0;
                text-align: center;
                line-height: 70px;
            }
            .gift-header-image {
                display: none !important;
            }
            .logo{
                margin-bottom: 30px;
            }
        }
        .gift-introduction {
            padding: 20px 0;
            background: none;
            .gift-introduction-text {
                margin-bottom: 30px;
            }
        }
        .bg-container {
            background-image: none;
            .gift-code-container {
                margin: 50px 0 0;
                .gift-code {
                    h1 {
                        margin-top: 0;
                        font-size: 40px !important;
                    }
                    .gift-box {
                        padding: 20px;
                        h3 {
                            margin: 20px 0 25px !important;
                            font-family: "Poppins-Bold";
                        }
                        .gift-image {
                            display: flex;
                            justify-content: center !important;
                        }
                    }
                    .inactive h2 {
                        font-size: 40px;
                    }
                }
            }
        }
        .gift-footer {
            margin-top: 50px;
        }
        .video-responsive {
            margin: 30px auto !important;
            display: flex;
            justify-content: center;
        }
    }
}

.gift-landing-content {
    max-width: 1200px !important;
    width: 100%; 
    margin: 50px 0 !important; 
    border-radius: 30px;
    background-image: url(../images/landings/gift/gift-bg.jpg); 
    background-repeat: no-repeat;
    background-size: cover;
    padding: 50px;
    position: relative;
    display: flex;
    align-items: flex-end;
    .gift-landing-img {
        max-height: 230px; 
        position: absolute;
        top: 0;
        left: 0;
    }
    .gift-landing-text {
        padding: 100px 30px 20px;

        .logo {
            height: 60px;
            margin-top: 30px;
            margin-bottom: 20px;
        }
        h1 {
            font-size: 45px;
            line-height: 42px;
            font-family: t.$poppins-bold;
            line-height: 13px;
            padding: 0;
            span{
                font-family: t.$poppins-light;
            }
        }
        p {
            font-size: 20px;
            margin-top: 20px;
            display:inline;
            span {
                font-family: t.$poppins-extrabold;
                font-size: 23px;
            }
            .signos{
                font-family: t.$poppins-extrabold;
                color: #68c6dd;
                display:inline;
            }
        }
        h2{ 

            margin-top: 30px;
            font-family: t.$poppins-bold;
            span{
                font-size: 18px;
                font-family: t.$poppins-regular;
            }
            svg{
                font-size: 20;
                font-weight: bold;
            }
        }
    }
    .active-gift-image{
        margin: 0 auto;
        width: auto;
        height: 200px;
        background: rgb(203,84,154);
        background: linear-gradient(90deg, rgba(203,84,154,1) 0%, rgba(139,23,103,1) 100%);
        border-radius: 20px;
        padding: 20px;
    }
    .gift-code-container{
        text-align: center;
        .gift-qr-text{
            h1{
                font-size: 25px;
                font-family: t.$poppins-extrabold;
                background-color: rgb(255 255 255 / 10%);
                border-radius: 30px;
                padding: 20px;
                width: 50%;
                margin: 0 auto;
                letter-spacing: 3px;
            }
            h3{
                font-size: 20px;
                font-family: t.$poppins-bold;
            }
            p{
                font-size: 18px;
            }
        }
    }
    .gift-response {
        padding: 30px auto;
        .download {
            border-radius: 15px;
            border: 1px solid #dedede;
            padding: 20px;
            width: 60%;
            margin: 0 auto;
            margin-top: 20px;
            h3 {
                font-size: 16px;
                font-weight: 900 !important;
            }
        }
        &.error {
            margin-top: 25px;
            text-align: center;
            line-height: 20px;
            h3,
            h4 {
                color: rgba(228, 46, 46, 0.781);
            }
            a {
                padding-top: 20px;
                font-weight: 900;
                text-decoration: none;
                color: rgba(228, 46, 46, 0.781);
            }
            svg {
                font-size: 35px;
                color: rgba(228, 46, 46, 0.781);
            }
        }
        &.success {
            margin-top: 25px;
            text-align: center;

            h3 {
                color: #269626;
                font-size: 18px;
                font-weight: 500;
                margin-top: 0;
                span {
                    font-weight: 900;
                }
            }
            svg {
                font-size: 45px;
                color: rgb(62, 40, 137);
                margin-bottom: 20px;
            }
            .success-svg {
                height: 50px !important;
            }
        }
    }
    .footer {
        .contact {
            .data {
                img {
                    width: 18px !important;
                }
            }
        }
    }
}

@media screen and (max-width: 1200px) {
    .gift-landing-content {
        margin: 25px 0 !important;
        padding: 0 0 40px;
        background-image: none !important; 
        display: flex;
        align-items: center !important;
        .gift-landing-logo {
            height: 60px;
            margin-bottom: 30px;
            margin: 0 auto !important;
        }
        .gift-landing-img {
            display: none !important;
        }      
        .gift-landing-text {
            text-align: center !important;
            padding: 0 !important;
            .logo{
                margin: 0;
            }
            h1{
                line-height: 0;
            }
            p{
                margin-top: 30px !important;
            }
            .responsive-title{
                line-height: 50px !important;
            }
        }
        .gift-code-container{
            border-radius: 30px;
            background-image: url(../images/landings/gift/gift-bg.jpg); 
            background-repeat: no-repeat;
            background-size: cover;
            padding: 20px !important;
            margin: 0 10px;
            .gift-qr-text{
                h1{
                    width: 100% !important;
                    padding: 10px 0;
                }
            }
        }
        
    }
}

.gift-activation-loader {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 75px 0;
}

.active-gift-image {
    width: 100%;
    height: auto;
}
