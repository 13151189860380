.no-data-container .no-data-image {
  width: 250px;
}
.no-data-container .no-data-text-container h1 {
  font-family: "Poppins-Bold";
}
.no-data-container .no-data-text-container .no-planes-image {
  height: 30px;
  margin: 0 0 0 5px;
}

