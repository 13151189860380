.container-pending .pending-image {
  width: 170px;
  height: 170px;
}
@media (min-width: 900px) {
  .container-pending .pending-image {
    width: 180px;
    height: 180px;
  }
}

