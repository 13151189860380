@use "content-card_config" as config;

.content-card-container {
    .card-item {
        h1 {
            font-family: "Poppins-SemiBold";
        }

        .bag-item-container { 
            transition: 0.8s;

            .service-item-wrapper {
                font-family: "Poppins-Regular";
                padding-bottom: 0;
                .service-item-title {
                    font-family: "Poppins-SemiBold";

                    .expand-icon {
                        color: config.$text-color-pink-links;
                    }
                }
            }
        }
    
    }
}
