/*** Tipografía ***/
@use "./typography.scss" as t;

@mixin center-loaders {
    position: absolute;
    top: 50%;
    right: 25%;
    left: auto;
}

 
/*** Variables ***/

$color-one: #050505;
$color-two: #ffffff;
$color-three: #7544ef;
$color-four: #441556;
$color-five: #fd45d9;
$color-six: #2ad5de;
$color-seven: #cc1616;
$color-eight: #29b77b;
$color-nine: #51328b;
$color-welcome: #2f2b31;
  
/*** Responsivo ***/
@mixin bigMobile() {
    @media screen and (max-width: 1280px) {
        @content;
    }
}
@mixin mobile() {
    @media screen and (max-width: 899px) {
        @content;
    }
}
@mixin miniMobile() {
    @media screen and (max-width: 767px) {
        @content;
    }
}

html,
body {
    margin: 0;
    padding: 0;
    background-color: #ffffff !important;
    font-family: t.$poppins-regular;
}

.full-width {
    width: 100% !important;
}

html,
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
li,
a {
    font-family: t.$poppins-regular;
}

.app-main {
    display: block;
    width: 100%;
    margin: 0;
}

form,
input,
label {
    font-family:  t.$poppins-regular !important;
}

.terms-conditions {
    span {
        font-weight: 500 !important;
        font-size: 15px;
        color: $color-one !important;
    }
    a {
        text-decoration: none;
        font-weight: 600;
        color: $color-five !important;
    }
    a:hover {
        color: #d827b5 !important;
    }
}
 
/* REMOVE INPUT ARROWS WHEN TYPE=NUMBER / UX */

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}


.success-form {
    h1 {
        font-family: t.$poppins-extrabold !important;
        font-size: 25px !important;
        margin-bottom: 0;
        color: white !important;
    }
    p {
        font-size: 18px;
    }
}

.purchase-form,
.activation-form {
    span {
        color: #8e8e8e;
        font-size: 14px;
        margin: 0;
    }
    .email-box {
        background: #ffffff;
        border-radius: 15px;
        padding: 15px 25px 10px;
        margin-top: 25px;
        border: 1px solid #cfcfcf;
        p {
            margin: 0px 0;
            line-height: 25px;
        }
    }
    .email-confirmation-box {
        background-color: #fff;
        padding: 20px;
        margin: 10px 0;
        border-radius: 10px;
        border: 1px solid #cdcdcd;
        p {
            margin-bottom: 20px;
            margin-top: 0;
        }
    }
    &.no-compatible {
        h2 {
            font-size: 35px !important;
            margin-bottom: 0;
        }
        h3 {
            font-size: 25px !important;
        }
        p {
            font-size: 20px;
        }
    }
    form {
        position: relative;
        padding: 10px 35px !important;
        height: auto !important;
        display: flex;
        flex-direction: column;
        .success-p {
            width: 100%;
            margin: 0 auto;
            font-size: 17px;
            h4 {
                font-size: 22px !important;
                font-family: t.$poppins-medium;
            }
        }
        .activation-text {
            span {
                font-weight: bold;
                color: $color-five;
            }
            img {
                display: flex;
                justify-content: center;
                margin: 0 auto;
                width: 100%;
            }
        }
        .purchase-text {
            h1 {
                font-family: t.$poppins-medium;
            }
            p {
                margin-bottom: 0px;
            }
            span {
                font-weight: bold;
                color: #000;
            }
        }
        .activation-title-container,
        .purchase-title-container {
            display: flex;
            align-items: center;
            height: 0;
        }
        .activation-textfield-container,
        .purchase-textfield-container {
            margin: 10px 0;
        }
        .centered {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            h1 {
                color: #181818;
                font-size: 30px;
                font-family: t.$poppins-medium;
                margin: 0;
            }

            h4 {
                font-size: 27px;
            }
            img {
                margin: 20px 0;
            }
            .span {
                font-weight: bold;
                color: $color-five;
            }
        }
        .confirmation-box {
            margin: 20px 0;
            padding: 10px 0;
            border: 1px solid #dddddd;
            border-radius: 10px;
            background-color: white;
            .total {
                margin: 20px 0 0;
                background-color: #f7f3ff;
                font-size: 22px;
                padding: 10px 30px !important;
                font-weight: bold;
                color: #2f2236;
            }
            .line {
                display: flex;
                padding: 0 30px;
                align-items: center;
                text-align: left;
                span {
                    font-weight: bold;
                    color: #383838;
                }
                p {
                    margin: 10px 0;
                    text-align: right !important;
                }
                .monthly {
                    font-family: t.$poppins-light;
                    font-size: 16px;
                    display: flex;
                    justify-content: flex-end;
                }
            }
        }
        .list {
            display: flex;
            flex-wrap: nowrap;
            margin: 15px 0;
        }
    }
    &.error {
        padding: 50px 50px 0;
        .activation-error-button-container,
        .purchase-error-button-container {
            justify-content: center;
            padding: 10px 0;
            margin: 0;
            .activation-error-button,
            .purchase-error-button {
                font-size: 17px;
                font-family: t.$poppins-bold;
                text-transform: none;
                color: $color-five !important;
            }
        }
    }
}
 

/* NAVIGATION */

.navigation {
    width: 100%;
    height: auto;
    color: #ffffff;
    padding: 20px 30px;  
    .logo-container {
        display: grid;
        align-content: center;
        .logo {
            width: 160px;
            height: auto;
            vertical-align: middle;
        }
    }
    .menu {
        text-align: end;
        li {
            list-style: none;
            display: inline;
            padding: 0 40px;
            font-family: t.$poppins-regular !important;
            a {
                text-decoration: none !important;
                color: white !important;
            }
        }
    }
}
@include miniMobile() {
    .navigation {
        padding: 10px;
        .logo-container {
            display: none;
        }
    }
}

/* SUBNAVIGATION */

.MuiMenu-list {
    font-size: 17px;
    color: white;
    font-family: t.$poppins-regular;

    background: #492677;
    box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset,
        rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
        rgba(0, 0, 0, 0.3) 0px 30px 60px -30px !important;
    svg {
        fill: white;
    }
    .MuiMenuItem-root {
        padding: 10px 20px;
    }
    .MuiMenuItem-root:hover {
        background: #441186;
    }
}

/** HEADER **/
.header {
    color: $color-two; 
    margin: 0 0 10px;
    .home { 
        h1 {
            font-size: 50px;
            font-family: t.$poppins-bold;
        }
        p {
            font-size: 28px;
            span {
                color: #34007a;
                font-weight: bold;
                background: #ffffff;
                padding: 2px 16px 4px;
                font-size: 27px;
                border-radius: 30px;
                font-family: t.$poppins-medium;
            }
        } 
        .image-container {
            display: flex;
            align-content: center;
            align-items: center;
            justify-content: center;
        }
    }
    @include miniMobile() {
        .home {
            padding: 0 30px;
        }
    }
    .products { 
        font-size: 30px;
        text-align: left;
        letter-spacing: 1px;
        .title {
            font-family: t.$poppins-regular !important;
            text-align: left;
            margin-top: 0;
            font-size: 52px;
            line-height: 40px;
            font-weight: 500;
        }
        .subtitle {
            font-size: 55px;
            color: #a361ff; 
            font-family: t.$poppins-bold;
        }
        .hypertitle {
            font-family: t.$poppins-bold !important;
            text-align: left;
            margin-top: 0;
            font-size: 56px;
            line-height: 40px;
            font-weight: 500;
        } 
    }
}

@include miniMobile() {
    .header {
        .home {
            margin: 0 auto !important;
            text-align: center !important;            
            p {
                padding-bottom: 20px;
            }
        }
        .button-container {
            display: flex;
            justify-content: center !important;
        }
        .header-image {
            display: none;
        }
    }
}

@media screen and (min-width: 899px) {
    .products-tab-container {
        margin-top: 35px !important;
       
    }
}

@media screen and (max-width: 600px) {
    .header {
        .products {
            margin-left: 0 !important;
            padding: 15px;
            .title {
                font-family: t.$poppins-regular !important;
                text-align: left;
                margin-top: 0;
                font-size: 48px;
                line-height: 40px;
                font-weight: 500;
            }
            .subtitle {
                font-size: 52px;
                color: #a361ff; 
                font-family: t.$poppins-bold;
            }
            .hypertitle {
                font-family: t.$poppins-semibold !important;
                text-align: left;
                margin-top: 0;
                font-size: 52px;
                line-height: 40px;
                font-weight: 500;
            } 
        }
    }
}

@media screen and (max-width: 1320px) {
    .products-image {
        position: absolute;
        right: 70px !important;
        .products-header-image {
            height: 365px !important;
        }
    }
}

@media screen and (max-width: 1000px) {
    .products-image {
        .products-header-image {
            display: none !important;
        }
    }
}

/** FOOTER **/

.footer-container {
    max-width: 100% !important;
    margin: 0 auto !important;
    display: block;
    padding: 30px 0 0;
    justify-content: center;
    h5 {
        font-size: 25px;
        margin: 0;
        font-family: t.$poppins-medium !important;
    }
    @include mobile() {
        .footer-container {
            max-width: 500px !important;
        }
    }
}

.footer {
    color: white;
    background: rgb(30, 0, 62);
    background: linear-gradient(190deg,rgb(30, 0, 62) 20%,rgb(112, 17, 167)); 
    h2 {
        font-size: 20px;
        font-family: t.$poppins-regular;
    }
    h3 {
        font-family: t.$poppins-regular;
        padding-right: 20px;
    }
    .stores {
        width: 120px;
    }
    ul {
        width: 100%;
        padding: 10px 0;
        display: block;
        li {
            text-align: left;
            width: 100%;
            margin: 0 auto;
            padding: 6px 0;
        }
    }
    .legales {
        li {
            list-style-type: square;
        }
    }
    .contact {
        display: flex;
        align-items: center;
        .data {
            display: flex;
            list-style-type: none;
            img {
                width: 30px;
            }
            a {
                font-size: 17px;
                text-decoration: none;
                color: white;
            }
            a:hover {
                color: $color-five;
            }
        }
    }

    .social-media {
        display: flex;
        flex-direction: column;
        align-items: center;
        background: rgb(0, 0, 0, 0.21);
        padding-top: 0 !important;
        padding-bottom: 20px;
        margin-top: 40px;
        a {
            img {
                width: 30px;
                margin-right: 20px;
            }
            img:hover {
                width: 25px;
                transition: 0.5s;
            }
        }
    }

    .mapa,
    .legales,
    .aviso {
        li {
            list-style-type: none;
            font-size: 15px;
            a {
                text-decoration: none !important;
                color: white !important;
            }
            a:hover {
                color: #fd45d9 !important;
            }
        }
    }
    .logo {
        width: 195px;
        height: auto;
        margin: left;
        display: block;
        padding-bottom: 15px;
    }
    .item {
        margin-top: 60px;
        text-align: center;
    }
    .bottom {
        text-align: center;
        background: rgb(30, 5, 56); 
        align-items: center;
        p {
            font-size: 15px;
        }
        button {
            background-color: transparent;
            color: white;
            font-family: t.$poppins-medium;
            border: solid 0px;
        }
        span {
            font-size: 1.1rem;
        }
    }
}

/** ELEMENTS **/

.button {
    cursor: pointer;
    padding: 10px 20px !important;
    text-align: center !important;
    border-radius: 50px !important;
    font-size: 18px !important;
    font-family: t.$poppins-medium !important;
    color: $color-two !important;
    background: rgb(171, 15, 137);
    background: linear-gradient(
        0deg,
        rgb(173, 39, 107) 0%,
        rgb(255 40 223) 100%
    ) !important;
    text-transform: none !important;
    border: none !important;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
        rgba(0, 0, 0, 0.3) 0px 3px 7px -3px !important;
    &.header-btn {
        margin-top: 20px;
        font-size: 22px !important;
        box-shadow: rgba(50, 50, 93, 0.35) 0px 30px 60px -12px,
            rgba(0, 0, 0, 0.3) 0px 18px 36px -18px !important;
    }
}

.button:hover {
    background: rgb(171, 15, 137);
    background: linear-gradient(
        0deg,
        rgb(147 26 87) 0%,
        rgb(238, 64, 212) 100%
    ) !important;
    &.header-btn:hover {
        transition: 1s;
        transform: scale(1.2);
    }
}

.svg {
    font-size: 17px !important;
    top: 50%;
    margin: 0 10px 0 0;
    vertical-align: text-bottom !important;
}

/*** HOME ***/

.active-plan,
.inactive-plan {
    width: 100%;
    border-radius: 10px;
    border: 1px solid #dfdfdf;
    padding: 10px 30px;
    margin: 50px 0;
    position: relative;
    display: flex;
    background-color: white;
    .plan-header {
        display: flex;
        align-items: flex-start;
        padding: 0 20px;
        h2 {
            color: $color-three;
            font-size: 26px;
            margin-bottom: 0 !important;
            font-family: t.$poppins-medium;
        }
        .plan-dates {
            p {
                font-size: 15px;
                color: rgb(24, 24, 24);
                padding: 0;
                span {
                    font-weight: 700;
                    color: rgb(78 78 78);
                    line-height: 25px;
                }
            }
        }
    }
    .inactive-text-container {
        align-content: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 0 0 30px;
        h2 {
            margin: 0;
            color: $color-three;
        }
        span {
            font-weight: 900;
            color: $color-five;
        }
        button {
            width: 50%;
            margin: 20px 0;
        }
    }
    .inactive-image-container {
        display: flex;
        justify-content: center;
        .inactive-image {
            width: 50%;
        }
    }
    @include miniMobile() {
        .inactive-image-container {
            display: none;
        }
    }
    .bags {
        text-align: center;
        padding: 10px 30px 30px;
        h4 {
            font-size: 18px;
            z-index: 900 !important;
            position: relative;
        }
        .free {
            width: 60%;
            background-color: #f3f2fe;
            color: $color-one;
            text-align: center;
            margin: 0 auto;
            padding: 3px 0;
            border-radius: 10px;
            margin-top: -30px;
            margin-bottom: 30px;
        }
        .detail {
            width: 100%;
            border-radius: 10px;
            border: 1px solid #dfdfdf;
            padding: 0 20px 10px;
            margin: 0 auto;
            display: block;
            h5 {
                text-align: left;
                font-size: 16px;
                color: #343434;
            }
        }
        .chart-container {
            margin: 0;
            top: -60px !important;
            position: relative;
            .chart {
                height: 260px;
            }
            h3 {
                font-size: 22px;
                margin: 0;
                color: $color-one;
            }
            h4 {
                font-size: 18px;
                margin: 0;
                color: $color-one;
            }
            p {
                font-size: 16px;
                margin: 5px;
            }
            .colored {
                color: $color-one;
                font-weight: 900;
            }
            .data {
                z-index: 900;
            }
        }
    }
}

/*** BOLSAS ***/
.products-tab-container {
    display: flex;
    align-content: center;
    justify-content: center;
    margin-top: 40px;

    .products-tab {
        border: 1px solid #cbc4cf;
        border-radius: 40px;
        padding: 2px;
        .tab {
            padding: 2px 10px;
            text-transform: none;
            font-size: 25px;
            font-family: "Poppins-Medium", "Helvetica", "Arial", "sans-serif" !important;
        }
    }
}

.bag-container {
    display: flex;
    align-items: center;
    .bag-card {
        height: auto;
        border-radius: 25px;
        text-align: center;
        justify-content: center;
        position: relative; 
        background: rgb(144, 205, 252);
        background: linear-gradient(
            34deg,
            rgba(144, 205, 252, 1) 0%,
            rgba(133, 108, 243, 1) 100%
        );
        color: white !important;
        border-bottom: 0;
        padding: 30px 0 20px;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        .megabytes {
            font-size: 82px;
            text-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
            p {
                margin: 0 !important;
                font-family: t.$poppins-black-italic;
                line-height: 80px;
                margin-bottom: -5px !important;
                span {
                    font-size: 23px !important;
                }
            }
        }
        .life {
            font-family: t.$poppins-regular;
            font-size: 16px;
            margin: 0 auto;
            display: block;
            span {
                font-size: 40px;
            }
        }
        .price {
            p {
                font-family: t.$poppins-regular;
                margin: 0;
                font-size: 50px;
                line-height: 60px;
                span {
                    font-size: 30px;
                }
            }
        }
        .bag-footer {
            margin: 10px 0 5px;
            .bag-button {
                height: 38px;
                margin: 0 auto;
                border: 0 solid;
                border-radius: 25px;
                padding: 3px 65px;
                font-size: 1.2em;
                color: white;
                text-transform: none !important;
                // background: rgb(123, 0, 231);
                // background: linear-gradient(
                //     90deg,
                //     rgba(123, 0, 231, 1) 0%,
                //     rgba(163, 0, 191, 1) 100%
                // );
                background-color: #f761ff;
                font-family: t.$poppins-regular;
                font-size: 14px !important;
            }
            .bag-button:hover {
                transform: scale(1.2);
                transition: 0.8s;
            }
            .description {
                width: 80%;
                margin: 0 auto !important;
                padding: 15px 0 0;
                small {
                    line-height: 20px;
                    font-family: t.$poppins-light;
                    font-size: 13px;
                }
            }
        }
    }
    a {
        text-decoration: none;
        padding: 15px 0 0;
        font-weight: bolder;
        font-size: 16px;
    }
}
.bag-container-lite {
    display: flex;
    align-items: center;
    .bag-card {
        height: auto;
        border-radius: 25px;
        background: linear-gradient(
            145deg,
            rgba(89, 16, 226, 1) 0%,
            rgba(79, 3, 145, 1) 100%
        );
        text-align: center;
        justify-content: center;
        position: relative;
        border-bottom: 0;
        color: white;
        padding: 10px 0 15px;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        .life {
            font-family: t.$poppins-medium;
            font-size: 30px;
            margin: 0 auto;
            display: block;
            span {
                font-size: 40px;
            }
        }
        .first-price {
            p {
                font-family: t.$poppins-extralight;
                font-size: 32px;
                margin: 0 0 3px;
                span {
                    font-size: 17px;
                }
                small {
                    font-size: 14px;
                }
                s {
                    display: inline-block;
                    text-decoration: none;
                    position: relative;
                }
                s:after {
                    content: "";
                    display: block;
                    width: 100%;
                    height: 50%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    color: #f761ff;
                    border-bottom: 2px solid;
                }
            }
        }
        .price {
            p {
                font-family: t.$poppins-bold;
                margin: 0;
                font-size: 62px;
                line-height: 60px;
                span {
                    font-size: 35px;
                    font-family: t.$poppins-semibold;
                }
                small {
                    font-size: 18px;
                    font-family: t.$poppins-semibold;
                }
                sup {
                    font-size: 18px;
                }
            }
        }
        .share-data {
            p {
                font-size: 14px;
                font-family: t.$poppins-medium-italic !important;
                margin: 0;
                img {
                    height: 15px;
                    margin-right: 10px;
                }
            }
        }
        .bag-footer {
            margin: 10px 0 5px;
            .bag-button {
                height: 38px;
                margin: 0 auto;
                border: 0 solid;
                border-radius: 25px;
                padding: 3px 65px;
                font-size: 1.2em;
                color: white;
                text-transform: none !important;
                background: #f761ff;
                font-family: t.$poppins-regular;
                font-size: 14px !important;
            }
            .bag-button:hover {
                transform: scale(1.2);
                transition: 0.8s;
            }
            .description {
                width: 80%;
                margin: 0 auto !important;
                padding: 15px 0 0;
                small {
                    line-height: 20px;
                    font-family: t.$poppins-light;
                    font-size: 12px;
                }
            }
        }
    }
    a {
        text-decoration: none;
        padding: 15px 0 0;
        font-weight: bolder;
        font-size: 16px;
    }
}
.bag-container-classic {
    display: flex;
    align-items: center;
    .bag-card {
        height: auto;
        border-radius: 25px;
        text-align: center;
        justify-content: center;
        position: relative;
        background: linear-gradient(
            145deg,
            rgba(61, 39, 112, 1) 0%,
            rgba(167, 1, 233, 1) 100%
        );
        color: white !important;
        border-bottom: 0;
        padding: 10px 0 15px;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        .life {
            font-family: t.$poppins-medium;
            font-size: 30px;
            margin: 0 auto;
            display: block;
            span {
                font-size: 40px;
            }
        }
        .first-price {
            p {
                font-family: t.$poppins-extralight;
                margin: 0 0 3px;
                font-size: 32px;
                span {
                    font-size: 17px;
                }
                small {
                    font-size: 14px;
                }
                s {
                    display: inline-block;
                    text-decoration: none;
                    position: relative;
                }
                s:after {
                    content: "";
                    display: block;
                    width: 100%;
                    height: 50%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    color: #f761ff;
                    border-bottom: 2px solid;
                }
            }
        }
        .price {
            p {
                font-family: t.$poppins-bold;
                margin: 0;
                font-size: 62px;
                line-height: 60px;
                span {
                    font-size: 35px;
                    font-family: t.$poppins-semibold;
                }
                small {
                    font-size: 18px;
                    font-family: t.$poppins-semibold;
                }
                sup {
                    font-size: 18px;
                }
            }
        }
        .share-data {
            p {
                font-size: 14px;
                font-family: t.$poppins-medium-italic !important;
                margin: 0;
                img {
                    height: 15px;
                    margin-right: 10px;
                }
            }
        }
        .bag-footer {
            margin: 10px 0 5px;
            .bag-button {
                height: 38px;
                margin: 0 auto;
                border: 0 solid;
                border-radius: 25px;
                padding: 3px 65px;
                font-size: 1.2em;
                color: white;
                text-transform: none !important;
                background: #f761ff;
                font-family: t.$poppins-regular;
                font-size: 14px !important;
            }
            .bag-button:hover {
                transform: scale(1.2);
                transition: 0.8s;
            }
            .description {
                width: 80%;
                margin: 0 auto !important;
                padding: 15px 0 0;
                small {
                    line-height: 20px;
                    font-family: t.$poppins-light;
                    font-size: 12px;
                }
            }
        }
    }
    a {
        text-decoration: none;
        padding: 15px 0 0;
        font-weight: bolder;
        font-size: 16px;
    }
}
.bag-container-plus {
    display: flex;
    align-items: center;
    .bag-card {
        height: auto;
        border-radius: 25px;
        background: linear-gradient(
            145deg,
            rgb(28, 9, 92) 0%,
            rgba(58, 9, 105, 1) 100%
        );
        text-align: center;
        justify-content: center;
        position: relative;
        border-bottom: 0;
        color: white;
        padding: 10px 0 15px;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        .life {
            font-family: t.$poppins-medium;
            font-size: 30px;
            margin: 0 auto;
            display: block;
            span {
                font-size: 40px;
            }
        }
        .first-price {
            p {
                font-family: t.$poppins-extralight;
                font-size: 32px;
                margin: 0 0 3px;
                span {
                    font-size: 17px;
                }
                small {
                    font-size: 14px;
                }
                s {
                    display: inline-block;
                    text-decoration: none;
                    position: relative;
                }
                s:after {
                    content: "";
                    display: block;
                    width: 100%;
                    height: 50%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    color: #f761ff;
                    border-bottom: 2px solid;
                }
            }
        }
        .price {
            p {
                font-family: t.$poppins-bold;
                margin: 0;
                font-size: 62px;
                line-height: 60px;
                span {
                    font-size: 35px;
                    font-family: t.$poppins-semibold;
                }
                small {
                    font-size: 18px;
                    font-family: t.$poppins-semibold;
                }
                sup {
                    font-size: 18px;
                }
            }
        }
        .share-data {
            p {
                font-size: 14px;
                font-family: t.$poppins-medium-italic !important;
                margin: 0;
                img {
                    height: 15px;
                    margin-right: 10px;
                }
            }
        }
        .bag-footer {
            margin: 10px 0 5px;
            .bag-button {
                height: 38px;
                margin: 0 auto;
                border: 0 solid;
                border-radius: 25px;
                padding: 3px 65px;
                font-size: 1.2em;
                color: white;
                text-transform: none !important;
                background: #f761ff;
                font-family: t.$poppins-regular;
                font-size: 14px !important;
            }
            .bag-button:hover {
                transform: scale(1.2);
                transition: 0.8s;
            }
            .description {
                width: 80%;
                margin: 0 auto !important;
                padding: 15px 0 0;
                small {
                    line-height: 20px;
                    font-family: t.$poppins-light;
                    font-size: 12px;
                }
            }
        }
    }
    a {
        text-decoration: none;
        padding: 15px 0 0;
        font-weight: bolder;
        font-size: 16px;
    }
}

.introduction-container {
    width: 100%;
    max-width: 260px;
    margin: 0 auto;
    .introduction {
        border-radius: 20px 20px 0px 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px 20px;
        background: rgb(34, 3, 91);
        background: linear-gradient(
            180deg,
            rgba(34, 3, 91, 1) 0%,
            rgba(93, 7, 154, 1) 100%
        );
        h1 {
            font-family: t.$poppins-bold;
            text-transform: uppercase;
            color: white;
            font-size: 18px;
            margin: 0;
        }
    }
}

.prepaid-share-data {
    color: $color-five;
}

.data-wrapper {
    justify-content: center;
    margin: 75px 0 50px;
}

.data-container {  
    font-family: "Poppins-regular";
    text-align: center;
    max-width: 970px;
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    &.purchase-data-container {
        background: linear-gradient(
            174deg,
            rgb(76 12 111) 0%,
            rgb(15 35 119) 100%
        ) !important;
        border: none;
        color: white !important;
        .line {
            height: 0.5px;
            background: #704ca1;
            max-width: 750px;
            width: 100% !important;
            margin: 0;
        }
    }
    .line {
        height: 0.5px;
        background: #26064e;
        max-width: 750px;
        width: 100% !important;
        margin: 0;
    }
    h2 {
        font-size: 25px;
        margin: 0;
        margin-bottom: 5px !important;
    }
    .black-italic {
        font-family: t.$poppins-bold-italic;
    }
    .data-content {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 10px 0;

        h1 {
            font-size: 40px;
            margin: 10px 0;
        }
        .megabytes {
            display: flex;
            justify-content: center;
            img {
                height: 30px;
                fill: $color-eight;
            }
        }
        .cobertura {
            text-align: left;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
                height: 30px;
                margin-right: 13px;
            }
            p {
                font-size: 20px;
                line-height: 30px;
                margin: 0;
                font-family: t.$poppins-medium;
            }
            span {
                font-family: t.$poppins-regular;
                font-size: 15px;
            }
        }
        .llamadas {
            text-align: left;
            font-family: t.$poppins-bold;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
                height: 30px;
                margin-right: 13px;
            }
            p {
                font-size: 20px;
                line-height: 30px;
                margin: 0;
            }
            span {
                font-size: 19px;
            }
        }
        .comparte {
            text-align: left;
            font-family: t.$poppins-bold;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
                height: 30px;
                margin-right: 13px;
                color: #21024b !important;
            }
            p {
                font-size: 20px;
                line-height: 30px;
                margin: 0;
            }
            span {
                padding-top: 7px;
                font-size: 19px;
            }
        }
    }
}

.social-container {
    border-radius: 0px 0px 20px 20px;
    width: 100%;
    max-width: 600px !important;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row !important;
    color: white;
    background-color: #26064e;

    h3 {
        margin: 10px 20px 10px 0;
        // margin-right: 20px;
        font-size: 18px;
    }

    img {
        height: 27px;
        color: white;
    }
    &.purchase-social-container {
        background-color: #1c2077;
    }
}
@include mobile() { 
    .data-container {
        border-radius: 15px 15px 0 0;
        .line {
            max-width: 310px;
        }
        .cobertura {
            display: block;
            margin: 0 auto;
            margin-bottom: 20px !important;
        }
        .comparte {
            margin: 20px 0 !important;
        }
    }
    .social-container {
        h3 {
            margin: 20px auto;
            margin-left: 0 !important;
        }
    }
}

@include miniMobile() {
    .social-container {
        h3 {
            margin: 20px auto !important;
            text-align: center !important;
        }
    }
}

.social-container-lite {
    width: 100%;
    max-width: 280px;
    margin: 0 auto;
    .social-media {
        border-radius: 0px 0px 20px 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px 20px;
        img {
            height: 38px;
            color: white;
        }
    }
}

.no-planes-box {
    margin: 30px 0 0;
    padding: 0 !important;
    .no-planes {
        display: flex;
        justify-content: center;
        align-items: center;
        &.img-1 {
            img {
                width: 64%;
            }
        }
        &.img-2 {
            img {
                width: 100%;
            }
        }
        @media only screen and (max-width: 899px) {
            &.img-1 {
                img {
                    width: 60%;
                }
            }
            &.img-2 {
                img {
                    width: 90%;
                }
            }
        }
    }
}

.products-leyend {
    display: flex;
    margin: 40px 0 20px !important;
    justify-content: center;
    font-size: 20px;
    text-align: center;
    &.purchase {
        color: white !important;
    }
    a {
        margin: 0 8px !important;
    }
}

.recommended.bag-content .bag-card {
    padding: 25px;
    border: 7.5px solid #fd45d9;
}

.recommended-container {
    position: relative;
    .recommended-label {
        display: block;
        position: absolute;
        z-index: 9;
        background: #fd45d9;
        border-radius: 5px;
        padding: 10px;
        top: -20px;
        left: 25%;
        right: 25%;
        color: white;
        font-weight: 600;
        font-style: italic;
        text-align: center;
    }
}

.recommended.mobile {
    display: none;
}

@include mobile() {
    .recommended {
        display: none;
    }
    .recommended.mobile {
        display: block;
    }
}

.accordion {
    border-radius: 10px;
    padding: 0 20px 70px;
    border-bottom: none;
    max-width: 1000px;
    flex-wrap: wrap;
    margin: 0 auto;
    h1 {
        text-align: center;
        font-family: t.$poppins-medium;
        margin-bottom: 50px;
        font-size: 1.5em;
        color: $color-nine;
    }
    .row {
        height: 60px;
        background-color: #fefefe;
        border-bottom: 1px solid #ddd;
        box-shadow: none;
        .accordion-title {
            font-size: 19px;
            font-family: t.$poppins-semibold;
            color: #51318f;
        }
        .accordion-title:focus {
            font-size: 25px;
            color: pink;
        }
    }
    &.accordion:not(.collapsed) {
        font-size: 1.3rem !important;
        color: #df1bc0 !important;
        background-color: #fff !important;
        box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
        font-family: "Poppins-Semibold", "Helvetica", "Arial", "sans-serif" !important;
    }
}

/* PAYMENT FORM */

.payment-container {
    padding-top: 20px !important;
    margin: 0 auto !important;
    display: flex !important;
    align-content: center;
    flex-wrap: nowrap !important;
    max-width: 1200px;
    .hide {
        display: none;
    }
    @media only screen and (max-width: 899px) {
        .hide-card {
            display: none;
        }
    }
}

.resume {
    padding: 0;
    background: white;
    border: 1px solid #e1e1e1;
    border-radius: 10px;
    margin: 30px 0;
    .head {
        color: white;
        background-color: #400295;
        text-align: left;
        font-size: 19px;
        font-family: "Poppins-Medium";
        padding: 5px 20px;
        line-height: 0;
        border-radius: 15px 15px 0 0;
        width: 100%;
    }
    .info {
        padding: 0 20px;
        display: flex;
        align-items: center;
        .rows {
            padding: 0;
        }
        p {
            font-weight: bold;
            font-size: 18px;
        }
    }
    .total {
        background-color: #faf8ff;
        border-radius: 0 0 15px 15px;
        color: $color-three;
        font-weight: bold;
        padding: 0 20px;
        border-top: solid 1px #ebebeb;
        span {
            font-size: 23px;
        }
    }
}

.payment-form {
    width: auto !important;
    margin: 0 auto !important;
    .section {
        padding: 10px 30px 30px;
        background: white;
        border: 1px solid #e1e1e1;
        border-radius: 15px;
        .title-section {
            display: flex;
            align-items: center;
            img {
                height: 25px;
                fill: $color-three;
                margin-right: 20px;
            }
        }
        .instruction {
            margin: 15px 0 5px;
        }
        h5 {
            color: $color-three;
            font-size: 25px;
            margin: 10px 0;
        }
    }
}

.amex-form,
.invoice-form {
    &.amex-purchase {
        background: #f9f5ff;
        padding: 10px 20px 0;
        border-radius: 10px;
        border: 1px solid #dfcccc;
        margin-bottom: 20px;
        h5 {
            color: #6b50af;
            font-size: 20px;
        }
        .payment-amex {
            input {
                height: 55px;
                width: 90%;
                border-radius: 5px;
                border: 1px solid #d4d4d4;
                padding: 0 15px;
                font-size: 16px;
                margin-bottom: 0 !important;
            }
            label {
                padding: 0;
                font-weight: 600;
                font-size: 15px;
                color: #5a5a5a;
            }
        }
    }
    display: flex;
    h6 {
        color: #3c3c3c;
        font-weight: 400;
        font-size: 15px;
        margin: 0 0 15px;
    }
    h5 {
        color: $color-three;
        font-size: 25px;
        margin: 10px 0;
    }
    span {
        font-size: 15x;
        color: #3c3c3c;
        font-weight: 400;
        margin: 5px 0;
    }
    .amex {
        flex-basis: 100%;
        margin: 10px 0;
    }
}

.payment-input {
    margin: 20px 0 !important;
    input {
        margin-top: 8px;
        height: 55px;
        width: 100%;
        border-radius: 5px;
        border: 1px solid #d4d4d4;
        padding: 0 15px;
        font-size: 16px;
    }
    input:hover {
        border: 1px solid $color-four !important;
    }
    label {
        padding: 0;
        font-weight: 600;
        font-size: 15px;
        color: #5a5a5a;
    }
}

.labels {
    input {
        margin-top: 8px;
        height: 60px;
        width: 100%;
        border-radius: 5px;
        border: 1px solid #d4d4d4;
        padding: 0 15px;
        font-size: 16px;
    }
    input:hover {
        border: 1px solid $color-four !important;
    }
    label {
        padding: 0;
        font-weight: 600;
        font-size: 15px;
        color: #5a5a5a;
    }
}

.label-dropdown {
    .label {
        padding: 0;
        font-weight: 600;
        font-size: 15px;
        color: #5a5a5a;
    }
    &.label-dropdown:hover {
        border: 1px solid $color-four !important;
    }
}

.email-box {
    background-color: #ffffff;
    border: 1px solid #e1e1e1;
    padding: 25px 20px 10px;
    margin: 10px 0;
    border-radius: 10px;
    p {
        margin: 0;
    }
}

.payment-button {
    cursor: pointer;
    width: 40% !important;
    margin: 0 auto !important;
    display: flex !important;
    justify-content: center !important;
    margin-bottom: 50px !important;
    padding: 10px 20px !important;
    text-align: center !important;
    border-radius: 50px !important;
    font-size: 18px !important;
    font-family: t.$poppins-medium !important;
    color: $color-two !important;
    background: rgb(171, 15, 137);
    background: linear-gradient(
        0deg,
        rgb(173 39 107) 0%,
        rgb(221, 57, 188) 100%
    ) !important;
    text-transform: none !important;
    border: none !important;
    &.header-btn {
        font-size: 22px !important;
    }
}

.payment-button:hover {
    background: rgb(171, 15, 137);
    background: linear-gradient(
        0deg,
        #971440 0%,
        rgb(221, 57, 188) 100%
    ) !important;
    transition: 0.2s;
}

.form-control {
    font-family: t.$poppins-regular !important;
}

.form-control:focus {
    color: #212529;
    background-color: #fff;
    border-color: purple !important;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(75, 6, 66, 0.25);
}

.text-align-right {
    h3 {
        text-align: right;
    }
}

.response-message {
    padding: 40px 0 50px;
    align-items: center;
    margin: 0 auto !important;
    text-align: center;
    .image {
        margin-top: 20px;
        width: 12%;
        height: auto;
    }
    @include miniMobile() {
        .image {
            width: 32%;
        }
    }
    .error-image {
        width: 12%;
        height: auto;
    }
    @include miniMobile() {
        .error-image {
            width: 25%;
        }
    }
    h1 {
        font-size: 2rem;
        margin: 40px 0 0;
        color: #3d3d3d;
        font-family: "Poppins-Medium", "Helvetica", "Arial", "sans-serif" !important;
    }
    &.success {
        h1 {
            color: #29cd98;
        }
    }
    &.error {
        h1 {
            color: #e02c2c;
        }
        h3 {
            margin: 10px 0 40px;
            font-weight: 500;
            font-size: 17px;
        }
    }
}

.transaction-detail {
    width: 100%;
    max-width: 500px;
    height: auto;
    border-radius: 20px;
    color: #3d3d3d;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0 auto;
    background-color: white;
    -webkit-box-shadow: 0px 5px 5px -2px #c4c4c4;
    box-shadow: 0px 5px 5px -2px #c4c4c4;
    font-family: "NotoSans-Medium", "Helvetica", "Arial", "sans-serif" !important;
    .transaction-title {
        background-color: #f7f5fb;
        padding: 5px 20px;
        h3 {
            font-size: 1rem;
            font-family: "NotoSans-Bold", "Helvetica", "Arial", "sans-serif" !important;
        }
    }
    .transaction-section {
        margin: 0 20px;
        .label {
            text-align: left;
            font-size: 16px;
            font-family: "NotoSans-Bold", "Helvetica", "Arial", "sans-serif" !important;
        }
        .response {
            p {
                font-family: "Poppins-medium";
                text-align: right;
                font-size: 18px;
            }
        }
        &.last {
            margin-bottom: 5px;
        }
    }
}

.action-button {
    background-color: #ff28dd !important;
    border: none !important;
    color: white;
    width: 190px;
    margin: 0 auto;
    border: 0 solid;
    border-radius: 40px !important;
    padding: 15px;
    font-family: "NotoSans-Bold", "Helvetica", "Arial", "sans-serif" !important;
}

@media only screen and (max-width: 1070px) {
    .header-container {
        padding: 30px;
    }
}

/* SHOPPING HISTORY */

.history-header {
    width: 100%;
    padding-top: 10px;
    .history-text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        h1 {
            font-size: 38px;
            font-family: t.$poppins-regular; 
            text-align: center;
            letter-spacing: 1px;
            min-height: 95px;
            span {
                color: #a361ff;
                font-family: t.$poppins-semibold;
            }
        }
        p {
            font-size: 22px;
            span {
                color: #a361ff;
                font-family: t.$poppins-medium;
            }
        }
    }
    @media only screen and (max-width: 899px) {
        .history-text {
            text-align: center;
            padding-left: 10px;
            padding-right: 10px;
            align-items: center;
            h1 {
                margin-top: 10px;
                font-size: 28px;
                font-family: t.$poppins-regular;
            }
            p {
                font-size: 19px;
                margin: 0;
                line-height: 26px !important;
                span {
                    color: #a361ff;
                    font-size: 31px;
                    font-family: t.$poppins-semibold;
                }
            }
        }
    }
}

/*** PAYMENT ***/

.card-box {
    background: rgb(120, 18, 191);
    background: radial-gradient(
        circle,
        rgb(136, 23, 216) 0%,
        rgba(62, 6, 126, 1) 100%
    );
    padding: 40px 0;
}

.card-form {
    min-width: 100% !important;
    display: flex;
    justify-content: center;
    padding: 40px 0;
}

/* MODAL */

.modalBackground {
    width: 100vw;
    height: 100vh;
    background-color: rgb(90, 87, 130, 0.1);
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    z-index: 9;
}

.modalContainer {
    border-radius: 12px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 15px;
    display: flex;
    flex-direction: column;
    padding: 25px;
}

.modalContainer .title {
    display: inline-block;
    text-align: left;
    margin-top: 10px;
    h3 {
        color: $color-four;
        margin: 3px 10px !important;
        font-family: t.$poppins-semibold !important;
    }
}

.titleCloseBtn {
    display: flex;
    justify-content: flex-end;
}

.titleCloseBtn button {
    background-color: transparent;
    color: #aea5a5;
    border: none;
}

.modalContainer .body {
    flex: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.7rem;
    text-align: center;
}

.modalContainer .footer {
    flex: 20%;
    display: flex;
    justify-content: right;
    align-items: center;
}

.modalContainer .footer button {
    width: 150px;
    height: 45px;
    margin: 10px;
    border: none;
    background-color: #7544ef;
    color: white;
    border-radius: 5px;
    font-size: 17px;
    cursor: pointer;
}

#cancelBtn {
    background-color: transparent;
    color: #7544ef;
}

.center_circular_progress {
    display: flex;
    justify-content: center;
    margin: 60px 0;
}

.elementHidden {
    display: none !important;
}

.required-incidence {
    margin: 0 !important;
}

.error-response-container {
    margin-top: 5px !important;
}
.error-response {
    margin-bottom: 5px !important;
}

.left-payment-options-dropdown {
    text-align: left;
}
.right-payment-options-dropdown {
    text-align: right;
}

.monthly-payment-dropdown #demo-simple-select {
    display: flex;
}

.add-card-section {
    &.hide-add-card-section {
        display: none;
    }
}

.cards-section .MuiFormControl-root {
    width: 100%;
    .card-section {
        margin: 10px 0;
        padding: 15px 0;
    }
}

.card-element {
    .card-checkbox label {
        margin: 0;
    }

    .card-checkbox,
    .card-image {
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            max-height: 30px;
        }
    }

    .card-name,
    .card-number {
        display: flex;
        justify-content: start;
        align-items: center;

        p {
            margin: 0;
        }
    }

    .card-name p {
        font-family: "NotoSans-SemiBold", "Helvetica", "Arial", "sans-serif" !important;
    }

    .card-number p {
        font-family: "NotoSans-Bold", "Helvetica", "Arial", "sans-serif" !important;
    }
}

.payment-container {
    width: 100% !important;
}

.portability-header {
    width: 100%;
    padding-top: 10px;
    .portability-text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        h1 {
            font-size: 38px;
            font-family: t.$poppins-regular;
            margin-bottom: 40px;
            text-align: center;
            letter-spacing: 1px;
            min-height: 95px;
            span {
                color: #a361ff;
                font-family: t.$poppins-semibold;
            }
        }
    }
    @media only screen and (max-width: 899px) {
        .portability-text {
            text-align: center;
            padding-left: 10px;
            padding-right: 10px;
            align-items: center;
            h1 {
                margin-top: 10px;
                font-size: 28px;
                font-family: t.$poppins-regular;
            }
            p {
                font-size: 19px;
                margin: 0;
                line-height: 26px !important;
                span {
                    color: #a361ff;
                    font-family: t.$poppins-semibold;
                }
            }
        }
    }
}

.portability-image-container {
    display: flex;
    align-items: center;
    align-items: center;
    justify-content: center;
}

.portability-image {
    padding: 0;
    width: 78%;
}

.clase-del-boton {
    // height: 100%;
    padding-bottom: 50px;
    button {
        max-height: 50px;
        margin-top: 10px;
        h4 {
            font-size: 17px;
            letter-spacing: 1px;
            font-family: t.$poppins-regular;
        }
    }
}

.portability-container {
    background: #fbfdff;
    padding: 10px 0 40px; 
}

.portability-form-container {
    width: 100% !important;
    margin: 0 auto;
    display: flex !important;
    justify-content: center;
    h2 {
        font-family: t.$poppins-medium;
        color: #51328b;
        font-size: 24px;
    }
}

.portability-text p {
    margin: 0;
    text-align: center;
    font-family: t.$poppins-regular;
    color: #2f2b31;
}

.portability-text.success-response {
    margin: 30px 0;
    text-align: center;
    font-family: t.$poppins-regular;
    font-size: 17px;
    color: #2f2b31;
}

.portability-form-content {
    border: 1px solid #d4d4d4;
    border-radius: 15px;
    margin: 35px auto 25px !important;
    padding: 35px 40px 20px; 
    .portability-instruction {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: flex-start;
        p {
            font-size: 16px;
            line-height: 30px;
            margin: 0px;
        }
    }
    .form-input {
        margin: 20px 0 30px !important;
    }
}

.portability-form-content .pink-number {
    font-family: t.$poppins-semibold;
    color: #ff28dd;

    border: 3px solid #fd45d9;
    border-radius: 100%;
    margin-right: 15px;
    padding: 0 10px;
    width: 30px;
    height: 30px;
    display: flex;
    text-align: center;
    justify-content: center;
}

.pink-text {
    font-family: t.$poppins-bold;
    color: #ff28dd;
}

@media (max-width: 1199px) {
    .portability-image {
        display: none;
    }
    .clase-del-boton {
        padding-bottom: 0;
    }
}

.response-container {
    &.error {
        margin: 0 !important;
        justify-content: center;
        text-align: center;
        // color: #D13852 !important;
    }
    .response-title,
    .response-text {
        font-size: 14px;
    }
    .response-title {
        margin-bottom: 10px;
    }
    .response-text {
        margin-top: 10px;
        font-family: t.$poppins-medium;
        font-size: 16px;
    }
}

.portability-title {
    padding: 30px 0 0;
    text-align: center;
    h3 {
        color: #51328b;
        font-family: t.$poppins-bold;
        font-size: 32px;
    }
}

.portability-check {
    display: flex;
    justify-content: center;
    svg {
        font-size: 55px !important;
        color: #6d39ef;
    }
}

.error-response-button {
    margin-top: 30px;
    border: none;
    background: none;
    color: #fd45d9;
    font-size: 16px;
    font-family: Arial;
    cursor: pointer;
}

.modal-error-icon {
    color: #d13852;
    display: flex;
    align-items: center;
    padding-right: 5px;
}

#modal-modal-title {
    text-align: center;
    display: flex;
    justify-content: center;
    .modal-error-title {
        font-size: 20px;
        font-family: t.$poppins-semibold;
    }
}

/***************************/
/* My Cards / Mis Tarjetas */

.my-cards-container,
.my-legals-container {
    margin: 0;
}

.my-cards-header,
.my-legals-header {
    justify-content: center;
}

.my-cards-title,
.my-legals-title {
    text-align: center;
    
    h3{
        margin-top: 10px;
        margin-bottom: 65px;
        font-size: 35px;
        font-family: t.$poppins-regular;
        color: white;
        span {
            color: #a361ff;
            font-family: t.$poppins-medium;
        }
    }
}

.my-legals-wrapper,
.my-cards-wrapper {
    justify-content: center;
    margin-top: 20px;
}

.centered {
    display: flex;
    justify-content: center;
}

.my-cards-content,
.my-legals-content {
    display: flex !important;
    justify-content: center !important;
    margin-top: 15px;
}

.my-cards-text,
.my-legals-text {
}

.my-cards-form-container,
.my-legals-form-container {
    justify-content: left;
    padding: 0 25px 50px !important;
    h1 {
        margin: 0 0 35px;
        color: #26202f;
        text-align: center;
        font-size: 19px;
        font-weight: 500;
    }
    .my-cards-form,
    .my-legals-form {
        background: #f9f9fc;
        border-radius: 15px;
        max-width: 550px;
        padding: 50px;
        border: 1px solid #fafaff;
        .data-container {
            .data-item {
                margin-bottom: 20px;
                &.centered {
                    display: flex;
                    justify-content: center;
                }
                label {
                    font-family: t.$poppins-regular !important;
                    margin-bottom: 20px;
                }
                .m-tb-20 {
                    margin: 10px 0 5px !important;
                }
                .required-incidence.m-0 {
                    margin: 0 0 15px !important;
                }
                .data-input {
                    font-family: t.$poppins-regular !important;
                    background-color: #ffffff;
                }
            }
        }  
        .button {
            padding: 10px 40px !important;
        }
    }

    .my-cards-form,
    .my-legals-form {
        padding: 30px 20px 20px;
        margin: 0 auto;
    }
}

.my-cards-items,
.my-legals-items { 
    display: flex;
    justify-content: flex-start;
    flex-direction: column !important;
    align-items: center;
    .my-cards-item {
        width: 75% !important;
    }
    .my-legals-item {
        width: 85% !important;
        display: flex;

    }
    .my-cards-item,
    .my-legals-item {
        margin: 0 0 15px;
        padding: 15px 25px;
        border-radius: 10px;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        border: 1px solid #d9d9d9;
        background-color: #ffffff;
        transition: all 2s linear 1s;
        &.selected {
            background-color: #ffffff;
            border: 3px solid #3900a8;
            .my-cards-item-text,
            .my-legals-item-text {
                color: #3900a8;
                font-family: t.$poppins-semibold;
                text-align: right;
            }
            .my-cards-item-image,
            .my-legals-item-image {
                svg {
                    fill: #3900a8;
                }
            }
        }
        .my-cards-item-image,
        .my-legals-item-image {
            display: flex; 
            img {
                width: 100%;
            }
            &.add-icon img {
                width: 75%;
                max-width: 25px;
            }
        }
        .my-cards-item-text,
        .my-legals-item-text {
            font-family: t.$poppins-medium;
            color: #3d3d3d;
            &.first {
                display: flex;
            }
            &.second {
                // text-align: center;
            }
            &.third {
                text-align: right;
            }
        }
    }
}

.selected-card-wrapper,
.selected-legal-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    .selected-card-container,
    .selected-legal-container {
        background: rgb(194, 27, 200);
        background: linear-gradient(
            180deg,
            rgba(194, 27, 200, 1) 0%,
            rgba(65, 42, 130, 1) 100%
        );
        box-shadow: rgb(16 9 23 / 35%) 0px 25px 50px -12px;
        border-radius: 15px;

        max-width: 440px;
        max-height: 220px;

        padding: 35px;

        .selected-card-content,
        .selected-legal-content {
            justify-content: space-between;
            font-size: 22px;
            font-weight: 500;
            letter-spacing: 1px;

            img {
                width: 100%;
                height: auto;
                max-width: 75px;
            }
            svg {
                font-size: 16px;
                margin-right: 5px;
            }
            button {
                color: #ffffff;
                font-family: t.$poppins-bold;
                font-size: 14px;
                padding: 0;
            }

            .selected-card-text,
            .selected-legal-text {
                &.first {
                    padding: 75px 25px 0 0;
                    text-align: left;
                }
                &.last {
                    padding: 75px 0 0 25px;
                    text-align: right;
                }
                font-family: t.$poppins-bold;
                color: #ffffff;
            }
        }
    }
}

.cards-right-container,
.legals-right-container {
    display: flex;
    justify-content: end;
}

.my-cards-modal,
.my-legals-modal {
    .my-cards-modal-content,
    .my-legals-modal-content {
        justify-content: center;
        align-content: space-between;
        min-height: 180px;

        &.centered {
            align-content: center;
        }

        .icon-centered {
            text-align: center;
            margin-bottom: 25px;
        }
    }
    .MuiBox-root {
        border-radius: 20px;
    }

    .modal-modal-text {
        color: $color-welcome;
    }

    #modal-modal-title {
        text-align: center;
        .modal-modal-text {
            font-family: t.$poppins-bold;
            font-size: 22px;
        }
    }

    #modal-modal-description {
        text-align: center;
        .modal-modal-text {
            font-family: t.$poppins-regular;
            font-size: 17px;
            line-height: 0;
        }
    }

    .modal-modal-buttons {
        margin: 0;
        display: flex;
        justify-content: flex-end;
        margin-top: 10px;
        .modal-modal-button {
            font-family: t.$poppins-bold;
            text-transform: none;
            font-size: 16px;
            &.modal-button-delete {
                padding: 0 30px 2px;
                background-color: #e62121;
                border-radius: 500px;
                color: #ffffff;
            }
            &.modal-button-back {
                color: #6b666e;
                margin-right: 15px;
            }
            &.modal-button-delete:hover {
                background-color: #c82828;
            }
        }
    }
}

.error-custom-modal-message-container {
    justify-content: center;
    p.error-custom-modal-message-title {
    }
    p.error-custom-modal-message-detail {
        line-height: 2;
    }
}

.my-card-add-button,
.my-legal-add-button {
    margin: 10px 0 !important;
}

.my-legal-save-button,
.my-legal-delete-button {
    display: flex;
    align-content: center;
    margin: 0;

    button {
        font-family: t.$poppins-medium !important;
        font-size: 17px !important;
    }
}

.my-legal-save-button {
    justify-content: end;
}

.my-legal-save-button button {
    color: $color-five !important;
}
.my-legal-save-button button:hover {
    border-radius: 10px;
    background-color: rgba(218, 86, 214, 0.15);
}
.my-legal-delete-button button {
    color: #ff2727 !important;

    svg {
        font-size: 15px;
        margin-right: 5px;
    }
}
.my-legal-delete-button button:hover {
    border-radius: 10px;
    background-color: rgb(255 217 217 / 52%);
}

.suscription-container {
    display: flex !important;
    justify-content: center;
    align-items: center;
    margin: 20px 0;

    img {
        height: 340px;
    }
    .card-suscription {
        border: 1px solid #dcdcdc;
        border-radius: 30px;
        margin: 35px 0;
        padding: 30px 40px;
        background-color: #f9f9fc;
        p {
            font-size: 17px;
            span {
                font-family: t.$poppins-bold;
                margin-left: 5px;
            }
        }
        .color-text-suscription {
            font-family: t.$poppins-extrabold;
            font-size: 35px;
            line-height: 45px;
            top: 252px;
            left: 360px;
            width: auto;
            height: auto;
            letter-spacing: 0px;
            color: #4c2793;
            opacity: 1;
            margin: 0 !important;
        }

        .text-container-suscription {
            display: flex;
            flex-direction: column;
            padding: 0;
            .title-container-suscription {
                p {
                    line-height: 45px;
                }
            }
            .suscription-btn {
                display: flex;
                justify-content: flex-start;
                margin: 20px 0 0 !important;
            }
        }
    }
}

.card-suscription-active {
    border: 1px solid #dcdcdc;
    border-radius: 30px;
    margin: 35px 0;
    padding: 30px 40px;
    background-color: #f9f9fc;

    .color-text-Dalefon-tittle {
        font-size: 85px !important;
    }
    .color-text-suscription {
        font-family: t.$poppins-extrabold;
        font-size: 32px;
        line-height: 45px;
        top: 252px;
        left: 360px;
        width: auto;
        height: auto;
        letter-spacing: 0px;
        opacity: 1;
        margin: 0 !important;
    }
    .left-column {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        p {
            line-height: 60px !important;
            color: #454545;
        }
        .duration-product {
            color: #29292a;
            font-size: 20px;
            margin: 0 0 10px;
            .renew-product {
                font-family: t.$poppins-extrabold;
                font-size: 23px;
                margin-left: 5px;
            }
        }
    }
    .right-column {
        display: flex;
        justify-content: center;
    }
}

.color-text-programming-package {
    font-family: t.$poppins-semibold;
    font-size: 20px !important;
    text-align: center;
    letter-spacing: 0px;
    opacity: 1;
    margin: 0 0 20px;
}

.color-text-choose_recharge {
    font-family: t.$poppins-regular;
    font-size: 18px;
    top: 322px;
    left: 653px;
    width: 275px;
    height: 55px;
    text-align: left;
    letter-spacing: 0px;
    color: #2f2b31;
    opacity: 1;
}

.color-text-choose-renew {
    font-family: t.$poppins-bold;
    font-size: 18px;
    top: 322px;
    left: 653px;
    width: 275px;
    height: 55px;
    text-align: left;
    letter-spacing: 0px;
    color: #2f2b31;
    opacity: 1;
}

.background-choose-renew {
    /* UI Properties */
    background: #ff00cc; /* fallback for old browsers */
    background: -webkit-linear-gradient(
        to right,
        #333399,
        #ff00cc
    ); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(
        90deg,
        rgba(72, 119, 238, 0.09),
        rgba(157, 44, 222, 0.09)
    );

    border-radius: 15px;
    padding: 30px 10px 25px;
}

.color-text-Dalefon-tittle {
    font-family: t.$poppins-extrabold;
    font-size: 65px !important;
    top: 347px;
    left: 358px;
    width: auto;
    height: auto;
    text-align: left !important;
    letter-spacing: 0px;
    color: #4c2793;
    opacity: 1;
    text-align: center;
    margin: 18px 0 !important;
}

.button-cancel-suscription {
    font-size: 17px !important;
    text-transform: none !important;
    font-family: t.$poppins-bold !important;
    color: #fd45d9 !important;
    margin-top: 30px !important;
}
.button-cancel-suscription:hover {
    background: #fcedff;
}

.title-product {
    h1 {
        padding-top: 30px;
        color: #51328b;
        font-family: t.$poppins-bold !important;
        font-size: 32px !important;
        justify-content: center;
        margin: 0 auto;
    }
}

.cancel-suscription-container {
    display: flex;
    justify-content: center;
}

.product-coverage-container {
    text-align: center;
    margin: 20px 0 0 !important;
    color: #585858;
}

.product-services-wrapper {
    justify-content: center;
    .product-services-container {
        img {
            display: block;
            max-width: 30px;
            margin: 0 auto;
        }
        p {
            text-align: center;
            margin: 10px 0 0 !important;
        }
        span {
            display: block;
            text-align: center;
        }
    }
}

/*******/
/* Flujo de Compra => Nuevas Imágenes */

.esim-compatible-container,
.sim-compatible-container {
    img {
        width: 75%;
        min-width: 300px;
        max-width: 400px;
    }
}

.congratulations-container {
    img {
        width: 25%;
        min-width: 295px;
        max-width: 300px;
    }
}

/*******/
/* Flujo de Compra => Loaders */

.purchase-form {
    span.MuiCircularProgress-root {
        color: #ffffff !important;
    }

    span.Circular-progress-payment {
        @include center-loaders;
        color: #ff28dd !important;
    }

    span.Circular-progress-product {
        width: 50px;
        height: 50px;
        position: absolute;
        top: 50%;
        left: 50%;
        margin: -25px 0 0 -25px;
    }

    span.Circular-progress {
        @include center-loaders;
        color: #ff28dd !important;
    }

    span.Circular-progress-client {
        @include center-loaders;
        color: rgb(255, 255, 255) !important;
    }
}

/*******/
/* Sesión de usuario / Dashboard => Servicios */

.services-page { 
    .services-container {
        padding: 30px 0 0;  
        margin: 0 auto; 
        margin-top: 10px; 
        background-color: white;        
        box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
        rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
        border-radius: 50px 50px 0 0;
        .services-content {   
            width: 100%;  
        }

        .services-tabs {
            .MuiTabs-flexContainer {
                display: flex;
                justify-content: center; 

                .services-tab {
                     
                    text-transform: none;
                    font-family: t.$poppins-medium;
                    font-size: 18px;
                    color: #685e80;

                    &.first {
                        border-right: none;
                        border-radius: 11px;
                    }

                    &.second {
                        border-right: none;
                        border-radius: 11px;
                    }

                    &.third {
                        border-left: none;
                        border-radius: 11px;
                    }
                }
            }

            .MuiTabs-indicator {
                background-color: transparent;
            }

            .MuiTab-root.Mui-selected {
                color: #fff;
                background: linear-gradient( 90deg, #b427ff 20%, #8677f4 100% )
                    no-repeat padding-box;
                font-family: t.$poppins-medium;
            }
        }
    }
}

.service-item-footer {
    width: 100%;
    margin: 0 0 10px;
    font-weight: 700;
    color: #52456a;
}

.data-alignment {
    margin: 0;
    font-size: 17px;
    font-family: t.$poppins-regular;
}

.data-alignment.colored {
    font-family: t.$poppins-extrabold;
    color: #2d263a;
    font-size: 18px;
}

.data-alignment,
.free-alignment p,
.free-alignment h4 {
    text-align: center;
}

.free-alignment {
    h4 {
        margin: 0;
        font-size: 24px;
        font-family: t.$poppins-bold;
        color: #2d263a;
    }
    p {
        font-size: 16px;
        font-family: t.$poppins-regular;
        margin: 0;
    }
}

.detail {
    margin-top: 15px !important;
}

.compatibility {
    display: flex;
    align-content: center;
    justify-content: center;
    .phones {
        display: flex;
        align-items: center;
        align-content: center;
        span {
            margin: 0 10px;
            color: rgb(38, 38, 38);
            svg {
                font-size: 20px;
            }
        }
    }
    button {
        background-color: transparent;
        color: #663ce6;
        font-family: t.$poppins-bold;
        text-transform: none;
    }
}

.whatsapp-image {
    height: 80px;
    margin: 0 0 20px !important;
}

/** PROMOTIONS */
.promotions-header {
    width: 100%;
    padding-top: 10px;
    padding-bottom: 20px;
    .promo-text {
        display: flex; 
        justify-content: center;
        h1 {
            font-size: 35px;
            font-family: t.$poppins-regular;
            margin-bottom: 55px;
            span {
                color: #a361ff; 
                font-family: t.$poppins-medium;
            }
        }
    } 
    @media only screen and (max-width: 899px) {
        .promo-text {
            text-align: center;
            padding-left: 10px;
            padding-right: 10px;
            h1 {
                margin-top: 10px;
                font-size: 30px;
                font-family: t.$poppins-regular;
                span {
                    color: #a361ff;
                    font-family: t.$poppins-medium;
                }
            }
        } 
    }
}

.promotions-content {
    padding: 40px 0 60px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    text-align: center;
    justify-content: flex-start;
    h1 {
        color: #51328b;
        font-size: 28px;
        font-family: t.$poppins-bold;
        font-size: 32px;
        margin-top: 0;
    }
    .card-container {
        max-width: 350px;
        margin: 20px 10px;
        .card {
            position: relative;
            width: 100%;
            height: 225px;
            border-radius: 20px;
            box-shadow: 0px 2px 18px 0px rgba(99, 99, 99, 0.5);
            background-color: #8f6e6e;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            transition: box-shadow 0.54s ease-in-out;
            .card__thumbnail {
                position: relative;
                overflow: hidden;
                margin: 0 !important;
                width: 100%;
                height: 100%;
                border-radius: 20px;
                img {
                    width: 100%;
                }
            }
        }
        .card:hover {
            cursor: pointer;
            box-shadow: 0 24px 38px 3px rgb(33 14 67 / 40%),
                0 9px 46px 8px rgba(135, 111, 178, 0.33),
                0 11px 15px -7px rgba(0, 0, 0, 0.2);
        }
    }
}
@media only screen and (max-width: 899px) {
    .promotions-content {
        h1 {
            font-size: 25px;
        }
    }
}

/*** MODAL ***/

.modalBackground {
    width: 100vw;
    height: 100vh;
    background-color: rgb(0 0 0 / 40%);
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99;
    overflow: hidden;
    .modalContainer {
        width: 400px;
        height: auto;
        border-radius: 20px;
        background-color: white;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        padding: 25px;
        .titleCloseBtn {
            display: flex;
            justify-content: flex-end;
        }

        .titleCloseBtn button {
            background-color: transparent;
            border: none;
            font-size: 20px;
            cursor: pointer;
        }
        .modal-title {
            display: inline-block;
            text-align: center;
            color: #050505;
            h1 {
                font-family: t.$poppins-medium !important;
                margin: 0;
                margin-top: -10px;
                margin-bottom: 10px;
                font-size: 25px;
            }
        }
        .modal-body {
            font-size: 16px;
            text-align: center;
            font-family: t.$poppins-regular;
            color: #000000;
            p {
                margin-bottom: 20px;
            }
            small {
                color: #666666;
                font-size: 14px;
            }
            span {
                font-family: t.$poppins-bold;
                color: #51328b;
            }
        }
        .modal-footer {
            flex: 20%;
            display: flex;
            justify-content: center;
            align-items: center;
            button {
                cursor: pointer;
                padding: 10px 25px;
                margin: 30px 0 10px;
                text-align: center;
                border-radius: 50px;
                font-size: 16px;
                font-family: t.$poppins-medium;
                color: #ffffff;
                background: rgb(171, 15, 137);
                background: linear-gradient(
                    0deg,
                    rgb(173, 39, 107) 0%,
                    rgb(255, 40, 223) 100%
                );
                text-transform: none;
                border: none;
            }
            button:hover {
                transition: 1s;
                transform: scale(1.12);
            }
        }
    }
}

.no-prod {
    width: 100%;
    padding: 15px 0;
    background: red;
    text-align: center;
    font-weight: 600;
    color: white;
}

/* SCSS - LANDING - QR ACTIVATION */
@import "./landings/qr-activation.scss";

/* SCSS - LANDING - INVOICE SIM PURCHASE */
@import "./landings/invoice-sim-purchase";

/* SCSS - LANDING - GIFT */
@import "./landings/gift";

/* SCSS - MODALS - UPDATE-CLIENT-DATA */
@import "./modals/update-client-data.scss";

/* SCSS - LOGIN - LOGIN-SIGNUP */
@import "./login/login-signup.scss";

/* SCSS - LANDING - GIFT ACTIVATION */
@import "./landings/gift-activation.scss";

/* SCSS - LANDING - LEAD RECOVERY */
@import "./landings/lead-recovery.scss";

.clasedeprueba {
    width: 100%;
    max-width: 1100px !important;
    margin: 0 auto !important;
    padding: 20px 0 0;
    position: relative;
}

@include mobile() {
    .products-tab {
        // width: 100%;
        div {
            justify-content: space-between;
            button.tab.main {
                margin: 0 !important;
                width: 47.5% !important;
                height: 70px !important;
                font-size: 22px !important;
            }
        }
    }

    .products {
        padding: 0 30px !important;
        h4 {
            text-align: center;
        }
    }

    .recommended-container .recommended-label {
        left: 25%;
        right: 25%;
    }
}

.bag-container.bag-content {
    .share-data {
        margin-bottom: 10px;
        p {
            font-size: 14px;
            font-family: t.$poppins-medium-italic !important;
            margin: 0;
            img {
                height: 15px;
                margin-right: 10px;
            }
        }
    }
}


/*** Número actualizado ***/

.number-changed-page {
    .number-changed-component {
        min-height: 100vh;

        padding: 100px 0 50px;

        background: transparent
            linear-gradient(181deg, #22035b 0%, #5d079a 100%) 0% 0% no-repeat
            padding-box;

        .number-changed-image {
            display: flex;
            justify-content: center;

            img {
                width: 300px;
            }
        }

        .number-changed-text {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;

            .number-changed-title {
                h1 {
                    font-family: t.$poppins-extrabold;
                    font-size: 30px;
                }
            }
            .number-changed-description {
                display: flex;
                justify-content: center;

                p {
                    max-width: 500px;
                    font-family: t.$poppins-regular;
                    font-size: 20px;
                }
            }
        }

        h1,
        p {
            color: white;
            text-align: center;
        }

        .number-changed-button {
            display: flex;
            justify-content: center;

            button {
                width: 100%;
                margin: 0 !important;
            }
        }
    }
}

.imei-popover .MuiPaper-elevation {
    max-width: 25vw !important;
}

@include mobile() {
    .imei-popover .MuiPaper-elevation {
        max-width: 85vw !important;
    }
}
