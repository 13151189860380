/*** Responsivo ***/



@mixin bigMobile() {
    @media screen and (max-width: 1280px) {
        @content;
    }
}

@mixin mediumMobile() {
    @media screen and (max-width: 1200px) {
        @content;
    }
}

@mixin mobile() {
    @media screen and (max-width: 899px) {
        @content;
    }
}
@mixin miniMobile() {
    @media screen and (max-width: 767px) {
        @content;
    }
}


@mixin center-loaders {
    position: absolute;
    top: 50%;
    right: 25%;
    left: auto;
}
