/*** Variables ***/
.container-content-no-plans-products .img-title-no-plans {
  max-width: 100%;
  max-height: 120px;
  margin: 0 auto;
}
@media (min-width: 900px) {
  .container-content-no-plans-products .img-title-no-plans {
    max-height: 140px;
  }
}

