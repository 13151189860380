/*** Variables ***/
.container-purchase .purchase-image {
  width: 90%;
  height: auto;
}
.container-purchase .terms-conditions-purchase {
  font-weight: 500;
}
.container-purchase .terms-conditions-purchase a {
  text-decoration: none;
  font-weight: 600;
  color: #ff00dd;
}
.container-purchase .terms-conditions-purchase a:hover {
  color: #d827b5 !important;
}

